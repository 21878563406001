//
//
//
//
//
//
//
//
//

export default {
    props: {
        src: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            innerSrc: ''
        }
    },
    computed: {
        ts() {
            return '&ts=' + new Date().getTime();
        }
    },
    watch: {
        src(n, o) {
            if(n != o) {
                if(!n) {
                    this.innerSrc = '';
                    this.reload();
                } else {
                    this.assign();
                };
            } else {
                this.reload();
            }
        }
    },
    methods: {
        assign() {
            if(!this.src) return;

            var pdfWin = this.$refs.frame.contentWindow;

            if(this.innerSrc !== this.src) {
                pdfWin.location.replace(`./static/plugin/pdfjs/index.html?file=${this.src}${this.ts}`);
            }
        },
        reload() {
            var pdfWin = this.$refs.frame.contentWindow;

            if (pdfWin.document.readyState === 'complete') {
                this.$refs.frame.contentWindow.webViewerLoad();
            } else {
                pdfWin.location.reload();
            };
        }
    },
    mounted: function() {
        this.assign();
    }
}
