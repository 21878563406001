//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        list: {
            type: Array,
            default: function() {
                return []
            }
        },
        props: {
            type: Object,
            default: function() {
                return {
                    url: 'url'
                }
            }
        }
    },
    data () {
        return {

        }
    },
    methods: {
        sliceUrl: function (href) {
            if (href) {
                if (/list/.test(href)) {
                    return href.split('/').slice(0, -1).join('/');
                } 
                
                return href;
            } 
            
            return '';
        }
    },
    mounted: function() {

    }
}
