//
//
//
//
//
//
//

export default {
    props: {
        src: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            innerSrc: ''
        }
    },
    watch: {
        src(n, o) {
            if(n != o) {
                this.innerSrc = '';
                this.assign();
            } else {
                this.reload();
            };
        }
    },
    methods: {
        assign() {
            if(!this.src) return;

            var frameWin = this.$refs.frame.contentWindow;

            if(this.innerSrc !== this.src) {
                frameWin.location.replace(this.src);
            };
        },
        reload() {
            var frameWin = this.$refs.frame.contentWindow;
            window.location.reload();
        }
    },
    mounted: function() {
        this.assign();
    }
}
