//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '50%'
        },
        showclose: {
            type: Boolean,
            default: true
        },
        hasBeforeClose:{
            type:[Function, Boolean],
            default:false
        },
        closeClickModal:{
            type: Boolean,
            default: false
        }
    },
    data () {
        return {

        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(n, o) {
                this.$emit('input', n);
            }
        }
    },
    methods: {
        beforeClose:function(done){
            if(typeof(this.hasBeforeClose) == 'function'){
                this.hasBeforeClose(done);
            }else{
                done();
            }
        },
        close(){
            this.$emit('close')
        },
        opened() {
            this.$emit('show')
        },
    },
    mounted: function() {

    }
}
