//
//
//
//

export default {
    props: {
        src: {
            type: String,
            default: ''
        }
    },
    data () {
        return {

        }
    },
    computed: {
        frameSrc() {
            return (`
                https://view.officeapps.live.com/op/view.aspx?src=${this.src}&ts=${new Date().getTime()}
            `);
        }
    },
    methods: {

    },
    mounted: function() {

    }
}
