//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            // type: [String, Number],
            default: '',
        },
        action: {
            type: String,
            default: '',
        },
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        props: {
            type: Object,
            default() {
                return {
                    value: 'key',
                    label: 'value',
                };
            },
        },
        readonly: {
            type: [Boolean, String, Number],
            default: false,
        },
        '2way': {
            type: String,
        },
        type: {
            type: String,
            default: '',
        },
    },
    data: function () {
        return {
            list: [],
            options: [],
        };
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (val) {
                this.$emit('input', val);
            },
        },
        selectedLabel() {
            return this.list.reduce((res, item) => {
                if (item[this.props.value] === this.value)
                    res = item[this.props.label];
                return res;
            }, '');
        },
    },
    watch: {
        data: {
            handler: function (n, o) {
                if (n != o) {
                    this.list = n;
                    this.options = this.list2map(n || []);
                }
            },
            deep: true,
        },
    },
    methods: {
        queryData: function () {
            if (!!this.action) {
                this.$get(this.action, function (data) {
                    this.list = data;
                    this.options = this.list2map(data || []);
                });
            } else {
                this.list = this.data;
                this.options = this.list2map(this.data || []);
            }
        },
        selectChange: function (item) {
            if (this['2way']) {
                var modelArr = this['2way'].split(',');
                modelArr.forEach(
                    function (key) {
                        this.$emit(
                            'update:' + key,
                            (this.options[item] || {})[key]
                        );
                    }.bind(this)
                );
            }

            this.$emit('select', this.options[item] || {});
        },
        list2map: function (list) {
            var that = this;
            return list.reduce(function (map, item) {
                map[item[that.props.value]] = item;
                return map;
            }, {});
        },
    },
    mounted: function () {
        this.queryData();
    },
};
