//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        url: {
            type: String,
            default: '',
        },
        search: {
            type: Object,
            default() {
                return {
                    sortname: 'addtime',
                    sortorder: 'desc',
                };
            },
        },
        summaryMethod: {
            type: Function,
            default: function () {},
        },
        showSummary: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: true,
        },
        height: {
            type: [String, Number],
        },
        maxHeight: {
            type: [String, Number],
        },
        theme: {
            type: String,
            default: '',
        },
        select: {
            type: Boolean,
            default: false,
        },
        selectable: {
            type: Function,
            default: function () {
                return true;
            },
        },
        spanMethod: {
            type: Function,
            default: function () {},
        },
        rowKey: {
            type: String,
            default: '',
        },
        cellStyle: {
            type: Object,
            default: () => ({ padding: '5px' }),
        },
    },
    data() {
        return {
            innerData: [],

            valueWatchLock: false,
        };
    },
    computed: {
        tableData() {
            return this.url ? this.innerData : this.data;
        },
    },
    watch: {
        value: {
            handler(n, o) {
                if (n === o || !n) return;
                if (!this.tableData.length) return;
                if (this.valueWatchLock) return;

                this.$nextTick(() => {
                    this.setRowSelection(n);
                });
            },
            deep: true,
        },
    },
    methods: {
        //表格选中项变化
        handleSelectionChange: function (node) {
            this.valueWatchLock = true;

            this.$emit('input', node);
            this.$emit('selectchange', node);

            this.$nextTick(() => {
                this.valueWatchLock = false;
            });
        },
        /*表格排序事件*/
        sortChange: function (sort) {
            this.search.sortname = sort.prop;
            this.search.sortorder = sortorder(sort.order);
            this.$emit('sort', this.search);
        },
        queryData: function () {
            if (!this.url) return;
            this.$get(this.url, this.search, function (data) {
                !!this.after &&
                    this.after(data, function (newData) {
                        if (newData) {
                            data = newData;
                        }
                    });
                this.innerData = data;
            });
        },
        setRowSelection(rows) {
            if (rows && rows.length) {
                rows.forEach((row) => {
                    var index = this.data.indexOf(row),
                        data = this.tableData;

                    this.$refs.table.toggleRowSelection(data[index]);
                });
            } else {
                this.$refs.table.clearSelection();
            }

            this.valueWatchLock = true;

            this.$nextTick(() => {
                this.valueWatchLock = false;
            });
        },
    },
    mounted: function () {
        this.queryData();
    },
};
