//
//
//
//
//
//
//

export default {
    props: {
        src: {
            type: String,
            default: ''
        }
    },
    data () {
        return {

        }
    },
    watch: {
        src(n, o) {
            if(n != o) {
                try {
                    this.$refs.frame.contentWindow.Init()
                } catch(e) {
                    this.$refs.frame.contentWindow.onload = function(e) {
                        e.currentTarget.Init();
                    }
                }
            }
        }
    },
    methods: {

    },
    mounted: function() {

    }
}
