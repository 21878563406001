//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: Object,
            default: () => ({
                time: [],
                title: '',
            })
        },
        titlePlaceholder: {
            type: String,
            default: '标题'
        },
        timeStartPlaceholder: {
            type: String,
            default: '添加时间早至'
        },
        timeEndPlaceholder: {
            type: String,
            default: '添加时间晚至'
        },
        alive: {
            type: Boolean,
            default: false
        }
    },
    data () {
        var that = this;

        return {
            pgData: {
                time: [that.value.starttime || '', that.value.endtime || ''],
                title: '',
            }
        }
    },
    watch: {
        pgData: {
            handler: function (val) {
                this.valReCalc(val)
            },
            deep: true
        }
    },
    methods: {
        onSearchSubmit: function () {
            if(this.alive) {
                this.storeSearch();
            }
            this.$emit('search');
        },
        storeSearch: function() {
            window.setSession('search[' + window.location.hash + ']', this.value);
        },
        valReCalc: function (val) {
            var n = window.clone(val);
            window.mixin(this.value, n);

            n.starttime = val.time ? val.time[0] || '' : '';
            n.endtime = val.time ? val.time[1] || '' : '';
            n.title = val.title;
            delete n.time;

            n.sortname = (n.sortname || n.sortname === '') ? n.sortname : 'addtime';
            n.sortorder = n.sortorder ? n.sortorder : 'desc';

            n.pagesize = Number(n.pagesize) || 20;

            this.$emit('input', n);

            return n;
        }
    },
    created: function () {
        if (this.alive) {
            var searchSession = window.getSession('search[' + window.location.hash + ']');

            if (searchSession) {
                searchSession.time = (!!searchSession.starttime && !!searchSession.endtime) ? [searchSession.starttime, searchSession.endtime] : [];

                this.pgData = searchSession;
            }
        } else {
            this.valReCalc(this.pgData);
        }
    },
    mounted: function () {
        // this.valReCalc(this.pgData);
    }
}
