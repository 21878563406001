//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        min: {
            type: [String, Number],
            default: '',
        },
        max: {
            type: [String, Number],
            default: '',
        },
    },
    data() {
        return {
            modelMin: '',
            modelMax: '',
        };
    },
    computed: {
        calcMin() {
            return this.getNum('min', this.modelMin, this.modelMax);
        },
        calcMax() {
            return this.getNum('max', this.modelMin, this.modelMax);
        },
    },
    methods: {
        updateValue() {
            if (this.modelMin === '' || this.modelMax === '') {
                return;
            }

            var min = this.getNum('min', this.modelMin, this.modelMax),
                max = this.getNum('max', this.modelMin, this.modelMax);

            this.modelMin = min;
            this.modelMax = max;

            this.$emit('update:min', this.modelMin);
            this.$emit('update:max', this.modelMax);
        },
        getNum(method, num1, num2) {
            return Math[method](Number(num1), Number(num2));
        },
        clearHandler() {
            this.$emit('update:min', '');
            this.$emit('update:max', '');

            this.modelMin = '';
            this.modelMax = '';
        },
    },
    created() {
        if (this.max === '' || this.min === '') {
            return;
        }

        this.modelMin = this.getNum('min', this.min, this.max);
        this.modelMax = this.getNum('max', this.min, this.max);
    }
};
